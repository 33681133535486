
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// select2
@import "node_modules/select2/dist/css/select2";

.form-search {
    margin-bottom: 15px;
}
